<template>
  <div class="qr">
    <img src="https://www.gdnandaedu.com/templets/images/qr.jpg" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
  .qr{
    width: 218px;
    margin: 70px auto;
  }
</style>
